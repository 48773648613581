import React from "react";
import {Link} from "react-router-dom";

export function Header () {
    return (
        <header id="header" className="default-header">
            <div className="container main-menu">
                <div className="row align-items-center justify-content-between d-flex">
                    <div id="logo">
					<span>
						<a href="/"><img src="img/new-photos/logo.png" alt="" title="" /></a>
					</span>
                        <span id="logo-text">
						Smart Endoscope
					</span>
                    </div>
                    <nav id="nav-menu-container">
                        <ul className="nav-menu">
                            <li className="menu-active"><Link to="/">Home</Link></li>
                            <li><Link to="/services">Services</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    );
}