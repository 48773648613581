import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import Contact from "./contact";
import Services from "./services";

ReactDOM.render(
    <BrowserRouter>
        <Routes>
            <Route path="/home" element={<App />} />
            <Route path="/" element={<Navigate to="/home"/>}/>
            <Route path="/contact" element={<Contact/>} />
            <Route path="/services" element={<Services/>} />
        </Routes>
    </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
