import {Header} from "./components/header";
import {Footer} from "./components/footer";
import React, {Component} from "react";
import {Helmet} from "react-helmet";

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTabIndex: 0,
        }
    }

    handleTabChange = (index) => {
       return this.setState({
            selectedTabIndex:index
        })
    }

    isActive = (index) => {
        return this.state.selectedTabIndex === index;
    }

    render() {
        const productsArr = [
            [
                {name: 'NTG100', src: 'img/products/NTG100.png', desc:
                        '▪ USB Design Compatible with Android, Windows PC, or Apple OSX' +
                        '45-Inch Flexible Borescope Probe\n' +
                        '▪ Fits .20 Caliber or Larger Barrels\n' +
                        '▪ LED Ring Light Illuminated\n' +
                        '▪ Removable Side View Mirror'},
                {name: 'NTG100H', src: 'img/products/NTG100H.png', desc:
                        '▪ USB Design Compatible with Android, Windows PC, or Apple OSX' +
                        '26-Inch Rigid Borescope Probe\n' +
                        '▪ Fits .20 Caliber or Larger Barrels\n' +
                        '▪ LED Ring Light Illuminated\n' +
                        '▪ Removable Side View Mirror'},
                {name: 'NTG100P', src: 'img/products/NTG100P.png', desc:
                    '▪ USB Design Compatible with Android, Windows PC, or Apple OSX' +
                        '10-Inch Rigid Pistol Probe\n' +
                        '▪ Fits .20 Caliber or Larger Barrels\n' +
                        '▪ LED Ring Light Illuminated\n' +
                        '▪ Removable Side View Mirror'
                },
            ],
            [
                {name: 'NTE100i', src: 'img/products/NTE100i.png', desc:
                    '▪ Easily Inspect Your Ear, Nose, Skin, Hair, or Mouth\n' +
                        '▪ iPhone & Android Compatible - Uses Charging Cable for Connection\n' +
                        '▪ Records & Saves Photos & Videos\n' +
                        '▪ Super Thin (4.3mm) Camera with LED Lights & Protective Tip\n' +
                        '▪ Ear Wax Removal Tools Included'
                },
                {name: 'SA39A', src: 'img/products/SA39A.png', desc:
                        '▪ Easily Inspect Your Ear, Nose, Skin, Hair, or Mouth\n' +
                        '▪ iPhone & Android Compatible - Uses WiFi Signal for Connection\n' +
                        '▪ Records & Saves Photos & Videos\n' +
                        '▪ Super Thin (3.9mm) Camera with LED Lights & Protective Tip\n' +
                        '▪ Ear Wax Removal Tools Included'
                },
                {name: 'SA39W', src: 'img/products/SA39W.png', desc:
                        '▪ Easily Inspect Your Ear, Nose, Skin, Hair, or Mouth\n' +
                        '▪ iPhone & Android Compatible - Uses WiFi Signal for Connection\n' +
                        '▪ Records & Saves Photos & Videos\n' +
                        '▪ Super Thin (3.9mm) Camera with LED Lights & Protective Tip\n' +
                        '▪ Ear Wax Removal Tools Included'
                }
            ],
            [
                {name: 'NTE390W', src: 'img/products/NTE390W.png', desc:
                        '▪ Easily Inspect Your Ear, Nose, Skin, Hair, or Mouth\n' +
                        '▪ iPhone & Android Compatible - Uses WiFi Signal for Connection\n' +
                        '▪ Records & Saves Photos & Videos\n' +
                        '▪ Super Thin (4.3mm or 3.9mm) Camera with LED Lights & Protective Tip\n' +
                        '▪ Ear Wax Removal Tools Included'
                },
            ],
            [
                {name: 'WF150', src: 'img/products/WF150.png', desc:
                   'The Teslong WF150 Wifi Adapter is a USB-compatible WiFi transmitter that will turn any of our USB borescopes and other USB inspection cameras into a WiFi borescope or inspection camera so it will be compatible with iOS devices including iPhones and iPads'
                },
                {name: 'TD500', src: 'img/products/TD500.png', desc:
                        '▪ Two-Way 210° Articulation on A 5.08-foot Probe\n' +
                        '▪ 720P CMOS Image Sensor for Excellent Detail\n' +
                        '▪ LED Ring Lights Illuminate the Darkest Areas\n' +
                        '▪ A 5-inch IPS LCD Screen Powered by a 3000 mAh Lithium Battery\n' +
                        '▪ 32GB Micro SD Card Included to Store Photos & Video'
                },
            ]

        ]
        return (
            <div className="Services">
                <Header/>
                <Helmet>
                    <title>Services</title>
                    <meta name="description" content="smart endoscope services"/>
                </Helmet>
                <section className="banner-area">
                    <div className="container">
                        <div className="row banner-content  d-flex align-items-center justify-content-between">
                            <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-between">
                                <div className="left-part">
                                    <h1>
                                        Our Services
                                    </h1>
                                    <p>Provide USB camera, Wifi camera integration solutions</p>
                                    <p>Provide software services for camera hardware solutions</p>
                                </div>
                            </div>
                            <div className="banner-img col-lg-4 col-md-6">
                                <img className="img-fluid" src="img/apps/banner.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="feature-area section-gap-top">
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-6">
                                <div className="section-title text-center">
                                    <h2>Unique Features</h2>
                                    <p>10+years experience，over 30 hardware and software engineer
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="single-feature">
                                    <div className="title">
                                        <span className="lnr lnr-book"/>
                                        <h3>Our services</h3>
                                    </div>
                                    <p>
                                        We provide Software SDK or smartphone and pc app to display and setting your
                                        camera product.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-feature">
                                    <div className="title">
                                        <span className="lnr lnr-book"/>
                                        <h3>Professional services</h3>
                                    </div>
                                    <p>
                                        Service more than 5 camera manufacturers and products.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-feature">
                                    <div className="title">
                                        <span className="lnr lnr-book"/>
                                        <h3>Customization service</h3>
                                    </div>
                                    <p>
                                        Customize development software or SDKS to better serve your users.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="support-products-area section-gap-top">
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-6">
                                <div className="section-title text-center">
                                    <h2>Support products</h2>
                                    <p>Over 20 kinds of endoscope products are supported
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <nav className="nav nav-pills flex-column flex-sm-row">
                                    <button className={"flex-sm-fill text-sm-center nav-link " + (this.isActive(0)? 'active':'')} onClick={() => this.handleTabChange(0)}>Rifle Borescopes</button>
                                    <button className={"flex-sm-fill text-sm-center nav-link " + (this.isActive(1)? 'active':'')} onClick={() => this.handleTabChange(1)}>Ear Endoscopes</button>
                                    <button className={"flex-sm-fill text-sm-center nav-link " + (this.isActive(2)? 'active':'')} onClick={() => this.handleTabChange(2)}>Digital Cameras</button>
                                    <button className={"flex-sm-fill text-sm-center nav-link " + (this.isActive(3)? 'active':'')} onClick={() => this.handleTabChange(3)}>Endoscopes</button>
                                </nav>
                            </div>
                            <div className="tab-content col-12 product-list">
                                {
                                    productsArr.map((products, i) => {
                                        return (
                                            <div className={"tab-pane fade " +(this.isActive(i)? 'active show': '')} role="tabpanel" aria-labelledby="nav-rifle-scopes" key={i}>
                                                <div className="row">
                                                    {
                                                        products.map((product,index )=> {
                                                            return (<div className="col-sm-6 col-md-4 mb-3 h-100 product" key={index}>
                                                                <div className="card card-span">
                                                                    <img className="rounded-3 h-100" src={product.src} alt={product.name}/>
                                                                    <div className="intro">
                                                                        <div className="title text-center">
                                                                            {product.name}
                                                                        </div>
                                                                        <div className="desc text-left">
                                                                            {product.desc}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>)
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </section>

                <section className="faq-area section-gap-top">
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-6">
                                <div className="section-title text-center">
                                    <h2>FAQ</h2>
                                    <p>Find the answers for the most frequently asked questions below
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 col-lg-4 mb-4">
                                <h6 className="mb-3 text-primary"><i className="fa fa-paper-plane text-primary pe-2"/> What should I do if the OTG device cannot be connected?</h6>
                                <div>
                                    <p>1.<strong><u>Please make sure</u></strong> the device is connected to the mobile phone.</p>
                                    <p>2.If there is an OTG selector in the phone settings, <strong><u>please make sure</u></strong> it is turned on.</p>
                                    <p>3.<strong><u>Please keep </u></strong>the phone's battery level above 50%.</p>
                                    <p>4.<strong><u>Please make sure</u></strong> that the ring light for the device's camera is on.</p>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4 mb-4">
                                <h6 className="mb-3 text-primary"><i className="fa fa-paper-plane text-primary pe-2"/> What should I do if I cannot connect to the NTE100i(AE430,AKE390) device?</h6>
                                <div>
                                    <p>1.Please make sure the device is turned on, please refer to the manual for details.</p>
                                    <p>2.If there is an OTG selector in the phone settings, <strong><u>please make sure</u></strong> it is turned on.</p>
                                    <p>3.<strong><u>Please check</u></strong> whether the battery power of the device is sufficient, please refer to the manual for detailed information</p>
                                    <p>4.<strong><u>Please make sure</u></strong> that the ring light for the device's camera is on.</p>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4 mb-4">
                                <h6 className="mb-3 text-primary"><i className="fa fa-paper-plane text-primary pe-2"/> What should I do if I cannot connect to the SA39A (SA39W,SA39E,SA39S,TD500) device
                                </h6>
                                <div>
                                    <p>1.<strong><u>Please make sure</u></strong> the device is turned on, please refer to the manual for details.</p>
                                    <p>2.<strong><u>Please make sure</u></strong> that the distance between the mobile phone and the device is close enough, and there is no strong interference in the surrounding network environment.</p>
                                    <p>3.<strong><u>Please make sure</u></strong> that the Wi-Fi connection of the mobile phone is Smart Otoscope-xxxx(WiFi_Monitor_xxxxx for TD500).</p>
                                    <p>4.<strong><u>Please check</u></strong> whether the power of the device is sufficient, please refer to the manual for detailed information.</p>
                                    <p>5.If you still cannot connect to the device, it may be because the device has been connected by someone else. If you don't know who is connected to the device, you can restart the device (long press the power button to shut down, and then turn on the device again).</p>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4 mb-4">
                                <h6 className="mb-3 text-primary"><i className="fa fa-paper-plane text-primary pe-2"/> What should I do if I cannot connect to the WF150/WF200 device?
                                </h6>
                                <div>
                                    <p>1.<strong><u>Please make sure</u></strong> the device is turned on, please refer to the manual for details.</p>
                                    <p>2.<strong><u>Please make sure</u></strong> that the distance between the mobile phone and the device is close enough, and there is no strong interference in the surrounding network environment.</p>
                                    <p>3.<strong><u>Please make sure</u></strong> that the Wi-Fi connection of the mobile phone is WIFI Cam_xxxx.</p>
                                    <p>4.<strong><u>Please check</u></strong> whether the power of the device is sufficient, please refer to the manual for detailed information.</p>
                                    <p>5.If you still cannot connect to the device, it may be because the device has been connected by someone else. If you don't know who is connected to the device, you can restart the device (long press the power button to shut down, and then turn on the device again).</p>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4 mb-4">
                                <h6 className="mb-3 text-primary"><i className="fa fa-rocket text-primary pe-2"/> Due to issues with the mobile phone system, the following solutions are provided.
                                </h6>
                                <div>
                                    <p>1.In the permission application dialog box on the connection page, select "Use by default for this USB device" and then select OK.</p>
                                    <p>2.Click "Connected" on the device list page and try it several times. The device can be used normally.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <Footer/>
            </div>
        );
    }
}

export default Services
