import './App.css';
import React from 'react';
import { isMobile } from "react-device-detect";
import {Footer} from "./components/footer";
import {Header} from "./components/header";
import axios from "axios";
import {Helmet} from "react-helmet";

class App extends React.Component{
  constructor(props) {
    super(props);
    this.state = {list: []}
  }
  componentDidMount() {
    // const PROJECT_ID = "fuxrrzni";
    // const DATASET = "production";
    // const QUERY = encodeURIComponent('*[_type == "device"]');

    // Compose the URL for your project's endpoint and add the query
    // const URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`
    const URL = 'http://localhost:1337/api/devices';
    // axios.get(URL).then(res => console.log(res));
  }

  render() {
    return (
      <div className="App">
        <Header/>
        <Helmet>
          <title>Home</title>
          <meta name="description" content="smart endoscope home page"/>
        </Helmet>
        <section className="home-banner-area">
          <div className="container">
            <div className="row fullscreen d-flex align-items-center justify-content-between">
              <div className="home-banner-content col-lg-6 col-md-6">
                <h1>
                  App That <br /> Connect Your camera
                </h1>
                <p>Smart Endoscope is a powerful camera connection tool. It can connect to USB cameras, Wifi cameras, and supports all Teslong devices.</p>
                {isMobile && (<div className="download-button d-flex flex-row justify-content-start mobile" >
                  <div className="buttons flex-row d-flex">
                    <i className="fa fa-apple" aria-hidden="true"/>
                    <div className="desc">
                      <a href="https://apps.apple.com/cn/app/smart-endoscope/id1540023976" target="_blank"  rel="noreferrer">
                        <p>
                          <span>Available</span> <br />
                          on App Store
                        </p>
                      </a>
                    </div>
                  </div>
                  <div className="buttons dark flex-row d-flex">
                    <i className="fa fa-android" aria-hidden="true"/>
                    <div className="desc">
                      <a href="https://play.google.com/store/apps/details?id=io.grus.app.smartcamera" target="_blank"  rel="noreferrer">
                        <p>
                          <span>Available</span> <br />
                          on Play Store
                        </p>
                      </a>
                    </div>
                  </div>
                </div>)}

                {!isMobile && (<div className="download-button d-flex flex-row justify-content-start mt-20 desktop">
                  <div className="buttons flex-row d-flex">
                    <i className="fa fa-windows" aria-hidden="true"/>
                    <div className="desc">
                      <a href="https://apps.apple.com/cn/app/smart-endoscope/id1540023976" target="_blank"  rel="noreferrer">
                        <p>
                          <span>Download</span> <br />
                          for Windows
                        </p>
                      </a>
                    </div>
                  </div>
                  <div className="buttons dark flex-row d-flex">
                    <i className="fa fa-apple" aria-hidden="true"/>
                    <div className="desc">
                      <a href="https://play.google.com/store/apps/details?id=io.grus.app.smartcamera" target="_blank"  rel="noreferrer">
                        <p>
                          <span>Download</span> <br />
                          for Mac
                        </p>
                      </a>
                    </div>
                  </div>
                </div>)}
              </div>
              <div className="banner-img col-lg-4 col-md-6">
                <img  className="img-fluid" id="banner-phone" src="img/apps/banner.png"  alt=""/>
              </div>
            </div>
          </div>
        </section>

        <section className="feature-area section-gap-top">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6">
                <div className="section-title text-center">
                  <h2>Key Features</h2>
                  <p>You can connect variety of microscopes and Endoscopes through Smart Endoscope<br />
                    Capture,Create Anything at AnyTime
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="single-feature">
                  <div  className="title">
                    <span className="lnr lnr-book"/>
                    <h3>Connect USB Camera</h3>
                  </div>
                  <p>
                    Connect UVC camera device directly to smartphone through USB OTG cable without particular convertible device
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-feature">
                  <div className="title">
                    <span className="lnr lnr-book"/>
                    <h3>High Compatibility</h3>
                  </div>
                  <p>
                    Smartphone:Support diverse devices with android version 4.3 and above <br />
                    UVC camera:supports UVC 1.1,UAC 1.0 spec compatible device
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-feature">
                  <div  className="title">
                    <span className="lnr lnr-book"/>
                    <h3>Fast processing speed</h3>
                  </div>
                  <p>
                    Supports 30 fps in high resolution UVC devices like HD and Full HD with image processing through H/W acceleration
                  </p>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="about-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 home-about-left">
                <img className="img-fluid img-normal" src="img/apps/usage2.png" alt=""/>
              </div>
              <div className="offset-lg-1 col-lg-5 home-about-right">
                <h1>
                  A Handy kit <br />
                  compatible with diverse scenes
                </h1>
                <p>
                  Smart Endoscope can be used with G-Scope to look at a very small thing through smartphone or tablet screen in situations like working on a watch and jewelry.
                  Also allows you to see inside a car bonnet with an endoscope.
                </p>
              </div>
              <div className="col-lg-6 home-about-right home-about-right2">
                <h1>
                  Observing
                  <br />
                  &Inspect<br />
                  with your phone
                </h1>
                <p>
                  Smart Endoscope can be used for educational purposes with Fixcope in situations like observing an insect.
                </p>
                <div className="download-button d-flex flex-row justify-content-start">
                  <div className="buttons flex-row d-flex">
                    <i className="fa fa-apple" aria-hidden="true"/>
                    <div className="desc">
                      <a href="https://apps.apple.com/cn/app/smart-endoscope/id1540023976" target="_blank"  rel="noreferrer">
                        <p>
                          <span>Available</span> <br />
                          on App Store
                        </p>
                      </a>
                    </div>
                  </div>
                  <div className="buttons dark flex-row d-flex">
                    <i className="fa fa-android" aria-hidden="true"/>
                    <div className="desc">
                      <a href="https://play.google.com/store/apps/details?id=io.grus.app.smartcamera" target="_blank"  rel="noreferrer">
                        <p>
                          <span>Available</span> <br />
                          on Play Store
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 home-about-left">
                <img className="img-fluid img-normal" src="img/apps/usage1.png" alt=""/>
              </div>
            </div>
          </div>
        </section>


        <section className="testimonials-area section-gap-top">
          <div className="container">
            <div className="testi-slider owl-carousel" data-slider-id="1">
              <div className="item">
                <div className="testi-item">
                  <h4>Shamshad Ali</h4>
                  <ul className="list">
                    <li><button><i className="fa fa-star"/></button></li>
                    <li><button><i className="fa fa-star"/></button></li>
                    <li><button><i className="fa fa-star"/></button></li>
                    <li><button><i className="fa fa-star"/></button></li>
                    <li><button><i className="fa fa-star"/></button></li>
                  </ul>
                  <div className="wow fadeIn" data-wow-duration="1s">
                    <p>
                      The app works great. In fact, the only app that worked on my endoscope.
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testi-item">
                  <h4>Thomas cunningham</h4>
                  <ul className="list">
                    <li><button><i className="fa fa-star"/></button></li>
                    <li><button><i className="fa fa-star"/></button></li>
                    <li><button><i className="fa fa-star"/></button></li>
                    <li><button><i className="fa fa-star"/></button></li>
                    <li><button><i className="fa fa-star"/></button></li>
                  </ul>
                  <div className="wow fadeIn" data-wow-duration="1s">
                    <p>
                      Top. The only one that works.
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testi-item">
                  <h4>Ceres Ambivalia</h4>/
                  <ul className="list">
                    <li><button><i className="fa fa-star"/></button></li>
                    <li><button><i className="fa fa-star"/></button></li>
                    <li><button><i className="fa fa-star"/></button></li>
                    <li><button><i className="fa fa-star"/></button></li>
                    <li><button><i className="fa fa-star"/></button></li>
                  </ul>
                  <div className="wow fadeIn" data-wow-duration="1s">
                    <p>
                      Works great with my endoscope, camera turned on with screen display after a quick one step activation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testi-item">
                  <h4>Алексей Зубков</h4>
                  <ul className="list">
                    <li><button><i className="fa fa-star"/></button></li>
                    <li><button><i className="fa fa-star"/></button></li>
                    <li><button><i className="fa fa-star"/></button></li>
                    <li><button><i className="fa fa-star"/></button></li>
                    <li><button><i className="fa fa-star"/></button></li>
                  </ul>
                  <div className="wow fadeIn" data-wow-duration="1s">
                    <p>
                      Excellent software for Teslong borescopes. Minimum advertising.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="owl-thumbs d-flex justify-content-center" data-slider-id="1">
              <div className="owl-thumb-item">
                <div>
                  <img className="img-fluid" src="img/testimonial/t1.jpg" alt=""/>
                </div>
                <div className="overlay overlay-grad"/>
              </div>
              <div className="owl-thumb-item">
                <div>
                  <img className="img-fluid" src="img/testimonial/t2.jpg" alt=""/>
                </div>
                <div className="overlay overlay-grad"/>
              </div>
              <div className="owl-thumb-item">
                <div>
                  <img className="img-fluid" src="img/testimonial/t3.jpg" alt=""/>
                </div>
                <div className="overlay overlay-grad"/>
              </div>
              <div className="owl-thumb-item">
                <div>
                  <img className="img-fluid" src="img/testimonial/t4.jpg" alt=""/>
                </div>
                <div className="overlay overlay-grad"/>
              </div>
            </div>
          </div>
        </section>

        <section className="screenshot-area section-gap-top">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6">
                <div className="section-title text-center">
                  <h2>Featured Screens</h2>
                  <p>
                    You can connect variety of microscopes and endoscopes through Smart Endoscope.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="owl-carousel owl-screenshot">
                <div className="single-screenshot">
                  <img className="img-fluid img-screens" src="img/apps/phone1.jpeg" alt=""/>
                </div>
                <div className="single-screenshot">
                  <img className="img-fluid img-screens" src="img/apps/phone2.jpeg" alt=""/>
                </div>
                <div className="single-screenshot">
                  <img className="img-fluid img-screens" src="img/apps/phone3.jpeg" alt=""/>
                </div>
                <div className="single-screenshot">
                  <img className="img-fluid img-screens" src="img/apps/phone4.jpeg" alt=""/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer/>
      </div>
    )
  }
}

export default App;
