import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Footer} from "./components/footer";
import {Header} from "./components/header";
import {Helmet} from "react-helmet";

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {placeholder: 'Messege', message: ''}
    }

    clearMessage = () => {
        this.setState((state) => {
            return {message: ''}
        })
    }

    sendMessage = () => {
        const subject = 'A message from ngsense.com'
        const message = this.state.message
        window.location.href = "mailto:smartendoscope.service@gmail.com?subject="+subject+"&body="+message;
    }

    handleChange = (e) => {
        return this.setState({message: e.target.value})
    }

    render(){
        return (
            <div className="Contact">
                <Header/>
                <Helmet>
                    <title>Contact</title>
                    <meta name="description" content="contact smart endoscope"/>
                </Helmet>
                <header id="header">
                    <div className="container main-menu">
                        <div className="row align-items-center justify-content-between d-flex">
                            <div id="logo">
                                <span>
                                    <Link to="/"><img src="img/new-photos/logo.png" alt="" title="" /></Link>
                                </span>
                                    <span id="logo-text">
                                    Smart Endoscope
                                </span>
                            </div>
                            <nav id="nav-menu-container">
                                <ul className="nav-menu">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/services">Services</Link></li>
                                    <li className="menu-active"><Link to="/contact">Contact</Link></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </header>

                <section className="banner-area">
                    <div className="container">
                        <div className="row banner-content">
                            <div className="col-lg-12 d-flex align-items-center justify-content-between">
                                <div className="left-part">
                                    <h1>
                                        Contact Us
                                    </h1>
                                    <p>
                                        We'd love to hear from you
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                <section className="contact-page-area section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 d-flex flex-column address-wrap">

                                <div className="single-contact-address d-flex flex-row">
                                    <div className="icon">
                                        <span className="lnr lnr-envelope"/>
                                    </div>
                                    <div className="contact-details">
                                        <h5>smartendoscope.service@gmail.com</h5>
                                        <p>Send us your query anytime!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div id="emailForm"  className="form-area contact-form text-right ">
                                    <div className="row">
                                        <div className="col-lg-12 form-group">
                                            <textarea className="common-textarea form-control email-message" name="message" id="message" placeholder={this.state.placeholder} onFocus={this.clearMessage} required="" onChange={this.handleChange}/>
                                        </div>
                                        <div className="col-lg-12 d-flex justify-content-between">
                                            <div className="alert-msg" style={{'textAlign': 'left'}}/>
                                            <button className="primary-btn" id="send-email" style={{'float': 'right'}} onClick={this.sendMessage}>Send Message</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        )
    }
}
export default Contact