import React from "react";
import { isMobile } from "react-device-detect";

export function Footer (){
    return (
        <footer className="footer-area section-gap">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-6 single-footer-widget">
                        <h4>Contact us</h4>
                        <ul>
                            <li>email: smartendoscope.service@gmail.com</li>
                        </ul>
                    </div>
                    <div className="col-lg-4 col-md-6 single-footer-widget">
                        <div className="social-link">
                            {isMobile && (<div className="download-button d-flex flex-row justify-content-end mobile">
                                <div className="buttons gray flex-row d-flex">
                                    <i className="fa fa-apple" aria-hidden="true"/>
                                    <div className="desc">
                                        <a href="https://apps.apple.com/cn/app/smart-endoscope/id1540023976" rel="noreferrer" target="_blank">
                                            <p>
                                                <span>Available</span> <br />
                                                on App Store
                                            </p>
                                        </a>
                                    </div>
                                </div>
                                <div className="buttons gray flex-row d-flex">
                                    <i className="fa fa-android" aria-hidden="true"/>
                                    <div className="desc">
                                        <a href="https://play.google.com/store/apps/details?id=io.grus.app.smartcamera" rel="noreferrer" target="_blank">
                                            <p>
                                                <span>Available</span> <br />
                                                on Play Store
                                            </p>
                                        </a>
                                    </div>
                                </div>
                            </div>)}
                            { !isMobile && (<div className="download-button d-flex flex-row justify-content-end desktop">
                                <div className="buttons gray flex-row d-flex">
                                    <i className="fa fa-windows" aria-hidden="true"/>
                                    <div className="desc">
                                        <a href="https://apps.apple.com/cn/app/smart-endoscope/id1540023976" target="_blank" rel="noreferrer">
                                            <p>
                                                <span>Download</span> <br />
                                                for Windows
                                            </p>
                                        </a>
                                    </div>
                                </div>
                                <div className="buttons gray flex-row d-flex">
                                    <i className="fa fa-apple" aria-hidden="true"/>
                                    <div className="desc">
                                        <a href="https://play.google.com/store/apps/details?id=io.grus.app.smartcamera" target="_blank" rel="noreferrer">
                                            <p>
                                                <span>Download</span> <br />
                                                for Mac
                                            </p>
                                        </a>
                                    </div>
                                </div>
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom row align-items-center">
                <p className="footer-text m-0 col-lg-12 col-md-12">
                    Copyright © 2020-2021 NgSense All Rights Reserved.</p>
            </div>
        </footer>
    )
}